import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AccreditationLevel } from 'src/app/api-model/enums/accreditation-level';

@Injectable()
@Pipe({ name: 'accreditationLevelName' })
export class AccreditationLevelNamePipe implements PipeTransform {

  public transform(value: AccreditationLevel, position: number, excluded = false): string {
    return excluded
      ? '<div class="icon-text-tag grey"><i class="fa-solid fa-ban"></i> Excluded</div>'
      : value === AccreditationLevel.Unaccredited
        ? `${AccreditationLevel[value]}${position ? ` <span class="subtle-badge">#${position}</span>` : ''}`
        : `<div class="internal-accreditation-badge ${AccreditationLevel[value].toLowerCase()}"><span class="level">${AccreditationLevel[value]}</span>${position ? `<span class="position">#${position}</span>` : ''}</div>`;
  }

}
