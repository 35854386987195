import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Auth0DecodedHash, Auth0Error, Auth0UserProfile, WebAuth } from 'auth0-js';
import { extend, first, isEqual, sortBy } from 'lodash-es';
import { BehaviorSubject, firstValueFrom, of, Subscription, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { INetworkDto } from 'src/app/api-model/network-dto';
import { IUserOrganisationDto } from 'src/app/api-model/user-organisation-dto';
import { environment } from '../../../environments/environment';
import { SessionStorageService } from '../services/session-storage.service';
import { AppUserProfile } from './app-user-profile';
import { AudienceType } from './audience-type';
import { Permissions } from './permissions';

declare var drift: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private refreshSubscription: Subscription;
  private _parseHashPromise: Promise<any>;
  private readonly _profile: AppUserProfile;
  // Expose user data and state as observables
  private readonly _idToken$: BehaviorSubject<string>;
  private readonly _accessToken$: BehaviorSubject<string>;
  private readonly _expiresAt$: BehaviorSubject<Date>;
  private readonly _profile$: BehaviorSubject<AppUserProfile>;
  private readonly _activeOrganisation$: BehaviorSubject<INetworkDto>;
  private readonly _scopes$: BehaviorSubject<string[]>;
  private readonly _authenticated$: BehaviorSubject<boolean>;
  private readonly auth0: WebAuth;

  public constructor(
    public router: Router,
    private readonly sessionStorage: SessionStorageService,
    private readonly http: HttpClient
    // NOTE: Don't import AudienceService here. Circular dependency problems will ensue.
  ) {
    this._idToken = '';
    this._accessToken = '';
    this._expiresAt = 0;
    this._scopes = '';
    this._profile = new AppUserProfile();

    this._idToken$ = new BehaviorSubject<string>(this.idToken);
    this._accessToken$ = new BehaviorSubject<string>(this.accessToken);
    this._expiresAt$ = new BehaviorSubject<Date>(this.expiresAt);
    this._profile$ = new BehaviorSubject<AppUserProfile>(this.profile);
    this._activeOrganisation$ = new BehaviorSubject<INetworkDto>(this.profile.activeOrganisation);
    this._scopes$ = new BehaviorSubject<string[]>(this.scopes);
    this._authenticated$ = new BehaviorSubject<boolean>(this.authenticated);

    this.auth0 = new WebAuth({
      clientID: environment.auth0ClientID,
      domain: environment.auth0Domain,
      responseType: 'token id_token',
      redirectUri: environment.auth0CallbackURL,
      audience: environment.auth0Audience,
      scope: this.requestedScopes
    });
  }

  private _idToken: string;

  /**
   * The identity token from Auth0. This contains claims about the user.
   * It is sensitive (personal) data, but not part of the security apparatus of authentication.
   */
  public get idToken(): string {
    return this._idToken;
  }

  private _accessToken: string;

  /**
   * The access token from Auth0. This is the secure, encrypted part of the JWT token used to authenticate
   * to API endpoints.
   */
  public get accessToken(): string {
    return this._accessToken;
  }

  private _expiresAt: number;

  /** The date/time when the current access token will expire */
  public get expiresAt(): Date {
    return this._expiresAt ? new Date(this._expiresAt) : null;
  }

  private _scopes: string;

  /** Gets the user's scopes (permissions) */
  public get scopes(): string[] {
    return (this._scopes || '').split(' ');
  }

  /**
   * Gets the user's profile information. Note that what is available in here depends on what scopes were
   * requested, as configured in the environment config file.
   */
  public get profile(): AppUserProfile {
    return this._profile;
  }

  /** An observable that gets updated when the user's profile changes */
  public get profile$() { return this._profile$.asObservable(); }

  /** An observable that gets updated when the user's active organisation changes */
  public get activeOrganisation$() { return this._activeOrganisation$.asObservable(); }

  /** An observable that gets updated when the user's scopes change */
  public get scopes$() { return this._scopes$.asObservable(); }

  public get canChangePassword() {
    return this.profile && this.profile.sub && this.profile.sub.startsWith('auth0');
  }

  /**
   * Tests if the user is currently authenticated with a valid (non-expired) access token
   */
  public get authenticated(): boolean {
    // Check whether the current time is past the access token's expiry time
    return this._accessToken && Date.now() < this._expiresAt;
  }

  /**
   * This will be true if we have an access token which is expired,
   * in which case an attempt to call renewTokensAsync makes sense
   */
  public get canAttemptTokenRenewal() {
    return this._accessToken && Date.now() > this._expiresAt;
  }

  private get requestedScopes() { return environment.auth0Scope; }

  /**
   * Initializes the auth service. Specifically, registers event handlers to detect navigation events that the
   * service needs to know about
   */
  public initialize() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (['/login', '/signup', '/logout', '/callback'].every(x => !event.url.startsWith(x))) {
          this.sessionStorage.set('loginReturnUrl', event.url);
        } else if (!event.url.startsWith('/callback')) {
          this.sessionStorage.set('loginReturnUrl', '');
        }
      }
      if (event instanceof NavigationEnd) {
        // Kill the preloader. We don't do it until this point to avoid content flashes during authentication.
        if (!event.url.startsWith('/callback')) {
          try { document.getElementsByClassName('pre-loader')[0].remove(); } catch { }
        }
      }
    });
  }

  /** Sends the user to a screen to change their password, if they are authenticated */
  public async changePassword() {
    if (!this.authenticated) {
      throw new Error('Cannot change password when not authenticated');
    }
    if (this.profile && this.profile.sub && this.profile.sub && !this.profile.sub.startsWith('auth0')) {
      throw new Error('Cannot change password because you are logged in with a third party account');
    }
    const url = await firstValueFrom(this.http.get<string>(`${environment.apiBase}auth/change-password-url`));
    if (url) {
      window.location.href = url;
    }
  }

  /** Sends the user to a screen to change their password, if they are authenticated */
  public async resendVerificationEmail(): Promise<boolean> {
    if (!this.authenticated) {
      throw new Error('Cannot resend verification email when not authenticated');
    }
    await firstValueFrom(this.http.get<string>(`${environment.apiBase}auth/resend-verification-email`));
    return true;
  }

  /** Changes the active organisation for the user, if they are authenticated */
  public async switchActiveOrganisation(organisation: { id?: string, shortCode?: string }, audienceType: AudienceType) {
    if (!this.authenticated) {
      throw new Error(`Cannot switch to a different organisation when not authenticated`);
    }
    // Send either the organisation ID or the short code - whichever is actually attached to the user's profile
    let organisationId = this.profile.organisationIds.find(x => x === organisation.id || x === organisation.shortCode);
    if (!organisationId && this.userHasScope(Permissions.admin)) {
      organisationId = this.profile.otherOrganisations
        .concat(this.profile.activeOrganisation)
        .find(x => x.id === organisation.id || x.shortCode === organisation.shortCode)
        .shortCode;
    }
    if (!organisationId) { throw new Error(`Organisation ID ${organisation?.id || organisation?.shortCode} is not associated with your user profile`); }
    await firstValueFrom(this.http.post(`${environment.apiBase}auth/switch-active-organisation/${AudienceType[audienceType].toLowerCase()}/${organisationId}`, ''));
    await this.renewTokens();
    return this.profile;
  }

  /** Forces the user to authenticate, sending them to the login screen if they aren't already authenticated */
  public login(): void {
    if (this.authenticated) { return; }
    this.auth0.authorize();
  }

  /** Direct the user to create a new account */
  public async signUp(email: string, inviteCode: string): Promise<any> {
    if (this.authenticated) { return; }
    try {
      await firstValueFrom(this.http.get(`${environment.apiBase}user-invitations/${encodeURIComponent(email)}/verify/${inviteCode}`));
    } catch (e) { if (e.status !== 404) { throw e; } } // Suppress 404
    this.auth0.authorize({
      screen_hint: 'signup',
      mode: 'signUp',
      appState: { invitationEmail: email, inviteCode: inviteCode }
    });
  }

  /**
   * Requires a user to be authenticated and sends them to the login page if they are not.
   * Also forces the user profile to be loaded, but this should have happened automatically
   * on login anyway.
   * @returns {Promise<boolean>} true if the user is already authenticated, or false if they
   * get redirected to the login page.
   */
  public async ensureLogin(): Promise<boolean> {

    // If the user is authenticated we need take no further action here
    if (this.authenticated && this._profile) {
      return true;
    }

    // If still parsing authentication or getting the user profile
    if (this._parseHashPromise) {
      await this._parseHashPromise;
    }

    // If the user is not authenticated, require them to log in
    if (!this.authenticated) {
      this.login.bind(this)();
      // Although the login call above will redirect the user to the login page, we return a promise that never
      // resolves here to effectively pause the consuming function and prevent flashes of content or other
      // behaviours in which the consuming function charges ahead without a valid user.
      return new Promise<boolean>(() => { });
    }

    // Ensure we have the user profile
    await this.getProfile();

    // If we arrived here, the user is fully authenticated and their profile is loaded
    return true;

  }

  /**
   * Requires the user to be authenticated and have the specified scopes (permissions).
   * If the user does not meet this criteria they are redirected to the access-denied page.
   * Uses ensureLogin() internally, so may redirect the user to the login page if they
   * are not authenticated.
   * @param scopes The scopes the user is required to have
   * @param any If true, the test passes if the user has any of the required scopes,
   * otherwise they must have all of them. Defaults to false.
   * @returns {Promise<boolean>} true if the user is already authenticated, or false if they
   * get redirected to the access-denied page or login page.
   */
  public async ensureUserHasScopes(scopes: string | string[], any = false): Promise<boolean> {
    const result = await this.ensureLogin();
    if (!result) { return false; }
    if (typeof scopes === 'string') { scopes = [scopes]; }
    if ((any && !this.userHasAnyScope(scopes)) || (!any && !this.userHasScopes(scopes))) {
      // User is denied access, redirect them to the access-denied page
      this.router.navigate(['/access-denied']);
      return false;
    }
    return true;
  }

  /**
   * Read the token from the URL if the current URL is the callback URL, then persists the
   * user details in-memory in this service, gets the user's profile and renews the tokens
   * in case they are nearly expired
   */
  public async handleAuthCallback(): Promise<any> {
    // If not returning from Auth0 on the callback URL, short circuit
    if (!window.location.pathname.startsWith('/callback')) {
      return this._parseHashPromise || Promise.resolve();
    }
    this._parseHashPromise = new Promise<void>(resolve => {
      this.auth0.parseHash(async (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {

          // Store the result of parsing the hash from the URL
          this.storeAuthResult(authResult);

          // Notify subscribers that the authenticated status may have changed
          this._authenticated$.next(this.authenticated);

          // Get the user's profile
          await this.getProfile();

          // If the user signed up with a different email address from the one in their invitation, complete the invitation now, then reauthenticate to ensure the user tokens are up to date
          const invitationEmail = authResult.appState?.invitationEmail;
          const inviteCode = authResult.appState?.inviteCode;
          if (invitationEmail && inviteCode && invitationEmail.toLowerCase() !== this.profile.email.toLowerCase()) {
            try {
              await firstValueFrom(this.http.get(`${environment.apiBase}user-invitations/${encodeURIComponent(invitationEmail)}/accept/${inviteCode}`));
              this.auth0.authorize();
              return;
            } catch (e) { // Suppress 404
              if (e.status !== 404) { throw e; }
            }
          }

          // Schedule token renewal
          this.scheduleRenewal();

          // Attempt to return to the last page the user was on before login, falling back to the landing page
          const returnUrl = this.sessionStorage.get<string>('loginReturnUrl');
          if (returnUrl) {
            try {
              this.router.navigate([returnUrl], { replaceUrl: true });
              return true;
            } catch {
              this.router.navigate(['/'], { replaceUrl: true });
            }
          } else {
            this.router.navigate(['/'], { replaceUrl: true });
          }

          resolve();
        } else if (err) {
          if (err.errorDescription === '`state` does not match.') {
            // Generally caused by the user reloading the page during the authentication process. Resolve by re-attempting login.
            this.login();
            resolve();
          } else {
            throw err;
          }
        } else {
          throw new Error(`Invalid response from auth0 parseHash: ${JSON.stringify(authResult)}`);
        }
        this._parseHashPromise = null;
      });
    });
    return this._parseHashPromise;
  }

  /**
   * Refreshes the user's idToken and accessToken using OAuth2 refresh token APIs from Auth0
   * @param skipProfile If true, the user profile will not be loaded after the tokens are refreshed
   */
  public async renewTokens(skipProfile = false): Promise<Auth0DecodedHash> {
    return new Promise<any>(resolve => {
      this.auth0.checkSession({}, async (err: Auth0Error, authResult: Auth0DecodedHash) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.storeAuthResult(authResult);

          // Notify subscribers that the authenticated status may have changed
          this._authenticated$.next(this.authenticated);

          if (!skipProfile) {
            await this.getProfile();
          }
          resolve(authResult);
        } else if (err) {
          if (err.error === 'login_required') {
            // Seems to be caused by a missing cookie when token renewal is attempted. Force re-authentication.
            this.login();
            resolve(null);
          } else {
            this.logout();
            throw err;
          }
        } else {
          throw new Error('Invalid response from auth0 checkSession');
        }
      });
    });
  }

  /**
   * Sets up an RxJS timer to refresh the user's tokens at the moment when they expire
   * (actually 30 seconds before they expire to guard against use immediately before expiry)
   */
  public scheduleRenewal() {
    if (!this.authenticated) { return; }
    this.unscheduleRenewal();
    const expiresAt = this._expiresAt;
    const source = of(expiresAt).pipe(
      mergeMap(_ => {
        // Use the delay in a timer to run the refresh at the proper time
        const renewInSeconds = expiresAt - Date.now() - 30000;
        return timer(Math.max(1, renewInSeconds));
      })
    );

    // Once the delay time from above is reached, get a new JWT and schedule additional refreshes
    this.refreshSubscription = source.subscribe(async () => {
      await this.renewTokens();
      this.scheduleRenewal();
    });
  }

  /**
   * Removes a previously scheduled timer to refresh the user's tokens. Principally intended to be
   * used internally by scheduleRenewal()
   */
  public unscheduleRenewal() {
    this.refreshSubscription?.unsubscribe();
  }

  /**
   * Logs the user out, removes all stored information about the user's session and returns the user
   * to the landing page
   */
  public logout(): void {
    this.setAccessToken('');
    this.setIdToken('');
    this.setExpiresAt(0);
    this.setProfile(new AppUserProfile());
    this.setScopes('');

    // Notify subscribers that the authenticated status may have changed
    this._authenticated$.next(this.authenticated);

    this.unscheduleRenewal();

    this.auth0.logout({
      returnTo: `${window.location.origin}/logout`
    });
  }

  /**
   * Makes a call to the Auth0 API to get the user's profile information and stores
   * it in memory
   * @param ignoreCache If true, an API call will be made to get the user's profile info
   * even if we already have profile data in memory for the user
   */
  public async getProfile(ignoreCache = false): Promise<AppUserProfile> {
    if (!this._accessToken) {
      throw new Error('Access token must exist to fetch profile');
    }
    if (this.profile.user_id && !ignoreCache) {
      return Promise.resolve(this.profile);
    } else {
      return new Promise<any>(resolve => {
        this.auth0.client.userInfo(this._accessToken, async (err: Auth0Error, profile: Auth0UserProfile) => {
          if (err) {
            this.setProfile(new AppUserProfile());
            throw err;
          } else {

            // Create a new instance of the augmented profile class, and populate it with the values provided in the Auth0 response
            const augmentedProfile = new AppUserProfile(profile);

            // Enrich the profile with detail about the organisations to which the user is associated
            if (augmentedProfile.email_verified) {
              // If Auth0 told us the user has any organisations associated with their account, get the details of those organisations from our API
              const organisations = await firstValueFrom(this.http.get<IUserOrganisationDto[]>(`${environment.apiBase}auth/user-organisations`));
              // Set the user's active organisation to the organisation instance we got from our API that matches the active organisation ID set in Auth0 user_metadata
              augmentedProfile.activeOrganisation = organisations
                ? organisations.find(x =>
                  augmentedProfile.activeOrganisationIdOrShortName
                  && x.audienceType === augmentedProfile.audienceType
                  && (x.id === augmentedProfile.activeOrganisationIdOrShortName || x.shortCode === augmentedProfile.activeOrganisationIdOrShortName))
                || first(organisations)
                : {} as IUserOrganisationDto;
              // Store the details of the other (non-active) organisations in an array on the profile object
              augmentedProfile.otherOrganisations = organisations
                ? sortBy(organisations.filter(x => x.id !== augmentedProfile.activeOrganisationIdOrShortName
                  && x.shortCode !== augmentedProfile.activeOrganisationIdOrShortName), x => x.displayName.toLowerCase())
                : [];
            }

            // Set the profile information we received from Auth0, including augmented data from our call to get the details of the user's organisations.
            // This call will also update observables with the changed organisation information
            this.setProfile(augmentedProfile);

            // Try to identify the user to drift, but don't error if this fails
            try {
              drift.identify(profile.sub, {
                email: profile.email,
                name: profile.name
              });
            } catch (e) {
              console.error(e);
            }

            // Resolve the promise
            resolve(this._profile);
          }
        });
      });
    }
  }

  /**
   * Tests if the user has ALL of the scopes (permissions) in a specified set
   * @param scopes The scopes the user is required to have
   * @returns {boolean} true if the user has all of the required scopes, otherwise false
   */
  public userHasScopes(scopes: string[]): boolean {
    const grantedScopes = this.scopes;
    return this.authenticated && scopes.every(scope => grantedScopes.includes(scope));
  }

  /**
   * Tests if the user has ANY of the scopes (permissions) in a specified set
   * @param scopes The scopes the user is required to have at least one of
   * @returns {boolean} true if the user has any of the required scopes, otherwise false
   */
  public userHasAnyScope(scopes: string[]): boolean {
    const grantedScopes = this.scopes;
    return this.authenticated && scopes.some(scope => grantedScopes.includes(scope));
  }

  /**
   * Tests if the user has a specified the scope (permission)
   * @param scope The scope the user is required to have
   * @returns {boolean} true if the user has the required scope, otherwise false
   */
  public userHasScope(scope: string): boolean {
    const grantedScopes = this.scopes;
    return this.authenticated && grantedScopes.includes(scope);
  }

  private setAccessToken(value: string) {
    const oldValue = this._accessToken;
    this._accessToken = value;
    if (value !== oldValue) {
      this._accessToken$.next(value);
    }
  }

  private setIdToken(value: string) {
    const oldValue = this._idToken;
    this._idToken = value;
    if (value !== oldValue) {
      this._idToken$.next(this.idToken);
    }
  }

  private setExpiresAt(value: number) {
    const oldValue = this._expiresAt;
    this._expiresAt = value;
    if (value !== oldValue) {
      this._expiresAt$.next(this.expiresAt);
    }
  }

  private setProfile(value: AppUserProfile) {
    if (isEqual(value, this._profile)) { return; }
    const previousActiveOrganisationId = this.profile.activeOrganisationIdOrShortName;
    this._profile.reset();
    extend(this._profile, value);
    this._profile$.next(this.profile);
    if (this.profile.activeOrganisationIdOrShortName !== previousActiveOrganisationId) {
      this._activeOrganisation$.next(this.profile.activeOrganisation);
    }
  }

  private setScopes(value: string) {
    const oldValue = this._scopes;
    this._scopes = value;
    if (value !== oldValue) {
      this._scopes$.next(this.scopes);
    }
  }

  /**
   * Stores the result of decoding the token in the callback URL in-memory.
   * Do not persist to localStorage (https://www.rdegges.com/2018/please-stop-using-local-storage/)
   * @param authResult The decoded hash as provided by Auth0
   */
  private storeAuthResult(authResult: Auth0DecodedHash): void {
    // The encoded access token. This is what we need to send out in Authorization headers.
    this.setAccessToken(authResult.accessToken);
    // The encoded identity information (user claims)
    this.setIdToken(authResult.idToken);
    // Expiry time for the access token. Delivered as a number of seconds until expiry, but store in the form of a timestamp
    this.setExpiresAt((authResult.expiresIn * 1000) + Date.now());
    // Gives our user permissions
    this.setScopes(authResult.scope || '');
  }

}
