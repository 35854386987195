import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IClientMessageDto } from '../../api-model/client-message-dto';
import { AuthService } from '../auth/auth.service';
import { ErrorHandlerService } from '../error/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class MessageHubService {

  public messages$ = new EventEmitter<IClientMessageDto>();
  private _hubConnection: HubConnection;

  public constructor(
    private readonly zone: NgZone,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly auth: AuthService
  ) { }

  public initialize() {
    this.auth.activeOrganisation$.pipe(filter(x => !!x?.id), distinctUntilChanged(isEqual)).subscribe(() => {
      this.disconnect();
      this.connect();
    });
  }

  private async connect(noRetry = false): Promise<any> {
    if (this._hubConnection?.state === HubConnectionState.Connected) { return; }
    try {
      this.createConnection();
      this.registerEvents();
      await this._hubConnection.start();
    } catch (e) {
      if (noRetry) {
        this.errorHandlerService.handleError(e);
        return;
      }
      console.warn('Error establishing SignalR connection, retrying in 5 seconds...', e);
      setTimeout(() => this.zone.run(_ => this.connect(true)), 5000);
    }
  }

  private async disconnect(): Promise<any> {
    try {
      if (!this._hubConnection) { return; }
      if (this._hubConnection?.state === HubConnectionState.Disconnected) {
        this._hubConnection = null;
        return;
      }
      await this._hubConnection.stop();
      this._hubConnection = undefined;
    } catch (e) {
      console.warn('Failed to disconnect from SignalR', e);
    }
  }

  private createConnection() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.apiBase.replace(/\/v\d+/, '')}message-hub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          return this.auth.accessToken;
        }
      })
      .withAutomaticReconnect()
      .configureLogging(environment.showDebugInfo ? LogLevel.Debug : LogLevel.Error)
      .build();
  }

  private registerEvents(): void {
    this._hubConnection.on('receiveMessage', (message: IClientMessageDto) => this.messages$.emit(message));
    this._hubConnection.on('', (message: IClientMessageDto) => this.messages$.emit(message));
  }

}
