import { Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SessionStorageService } from './shared/services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingHelperService {

  private _isFirstRoute = true;

  public constructor(private readonly router: Router, private readonly sessionStorage: SessionStorageService) { }

  public initialize() {
    const subscription = this.router.events.pipe(filter(event => event instanceof ActivationStart)).subscribe((event: ActivationStart) => {
      if (this._isFirstRoute && (event.snapshot?.data.redirectOnRefresh || event.snapshot?.firstChild?.data.redirectOnRefresh)) {
        console.log(`loginReturnUrl when redirecting to dashboard because redirectOnRefresh: ${this.sessionStorage.get<string>('loginReturnUrl')}`);
        this.router.navigate(['/']);
      }
      this._isFirstRoute = false;
      subscription?.unsubscribe();
    });
  }

}
