import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-no-active-organisation',
  templateUrl: './no-active-organisation.component.html',
  styleUrls: ['./no-active-organisation.component.scss']
})
export class NoActiveOrganisationComponent implements OnInit {

  public constructor(
    public title: Title,
    public auth: AuthService,
    private readonly router: Router,
    private readonly toastr: ToastrService
  ) {
    this.title.setTitle(this.auth.profile?.anonymisedDataOnly ? 'Wrong Domain' : 'Welcome');
  }

  public get supportEmail() { return environment.supportEmail; }

  public get profile() { return this.auth.profile; }

  public ngOnInit(): void {
    // The user might refresh this page after an organisation has been added to their account. If so, redirect them to the dashboard.
    if (this.auth.profile.activeOrganisationId) {
      this.router.navigate(['./']);
    }
  }

  public async resendVerificationEmail() {
    await this.auth.resendVerificationEmail();
    this.toastr.success(`An email with a link to verify your account has been sent to <strong>${this.auth.profile.email}</strong>`,
      'Verification email sent', {
        positionClass: 'toast-top-center',
        enableHtml: true
      });
  }

  public hardReload() { document.location.href = document.location.origin; }

}
