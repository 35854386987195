<app-page-header [title]="title.getTitle()"></app-page-header>
<section class="page-area">
  <div class="padded-content top-bottom-padding">
    <app-content-header title="You are currently using a 'basic' account type"></app-content-header>
    <p>
      You are currently using the {{companyName}} {{productName}} on the free tier. This gives you basic usage data for use in understanding
      where and how traffic is being blocked, and where and how disputed installs are occurring.
    </p>
    <p>
      If you are interested in discussing working with {{companyName}} to get better insight into your traffic, and improving the quality of
      your inventory, please get in touch with our support team at <a class="clickable" href="mailto:{{supportEmail}}">{{supportEmail}}</a>
    </p>
  </div>
</section>
