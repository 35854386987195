// noinspection HttpUrlsUsage

export class AdvertiserClaimTypes {

  public static readonly advertiserIds: string = 'http://mchnad.com/ad/orgIds';
  public static readonly activeAdvertiserId: string = 'http://mchnad.com/ad/activeOrgId';

  public static all = [
    AdvertiserClaimTypes.advertiserIds,
    AdvertiserClaimTypes.activeAdvertiserId
  ];

}
