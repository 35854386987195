import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';
import { isDate } from 'ngx-bootstrap/chronos';

@Injectable()
@Pipe({ name: 'mchnDate' })
export class MchnDatePipe implements PipeTransform {

  public transform(value: string | Date | Moment, dateFormat: 's' | 'm' | 'l' | 'ds' | 'dm' | 'dl' = 'm', timeFormat: 'm' | 'ma' | 's' = null, yearMode: 'always' | 'never' | 'auto' = 'auto'): string {

    /**
     * dateFormat examples:
     *   s  (yearMode 'always' or year is current year):    1 Jan 19
     *   s  (yearMode 'never' or year is not current year): 1 Jan
     *   m  (yearMode 'always' or year is current year):    01 Jan 2019
     *   m  (yearMode 'never' or year is not current year): 01 Jan
     *   l  (yearMode 'always' or year is current year):    01 January 2019
     *   l  (yearMode 'never' or year is not current year): 01 January
     *   ds (yearMode 'always' or year is current year):    Wed 1 Jan 19
     *   ds (yearMode 'never' or year is not current year): Wed 1 Jan
     *   dm (yearMode 'always' or year is current year):    Wed 01 Jan 2019
     *   dm (yearMode 'never' or year is not current year): Wed 01 Jan
     *   dl (yearMode 'always' or year is current year):    Wednesday 01 January 2019
     *   dl (yearMode 'never' or year is not current year): Wednesday 01 January
     *
     * timeFormat examples:
     *   m:                 23:59
     *   s:                 23:59:59
     *   ma (not midnight): 23:59
     *   ma (midnight):     [empty string]
     */

    if (value && (typeof value === 'string' || isDate(value))) { value = moment.utc(value); }

    if (!moment.isMoment(value) || !value.isValid() || value.year() <= 1900) {
      switch (dateFormat) {
        case 's':
          return '-';
        case 'ds':
          return '-';
        case 'm':
          return 'Unknown';
        case 'dm':
          return 'Unknown';
        default:
          return 'Unknown Date';
      }
    }

    let format = '';

    // Day of week part
    switch (dateFormat) {
      case 'ds':
        format += 'ddd ';
        break;
      case 'dm':
        format += 'ddd ';
        break;
      case 'dl':
        format += 'dddd ';
        break;
    }

    // Date part
    const showYear = yearMode === 'always' ? true : yearMode === 'never' ? false : value.year() !== moment.utc().year();
    switch (dateFormat.slice(-1)) {
      case 's':
        format += `D MMM${showYear ? ' YY' : ''}`;
        break;
      case 'm':
        format += `DD MMM${showYear ? ' YYYY' : ''}`;
        break;
      case 'l':
        format += `DD MMMM${showYear ? ' YYYY' : ''}`;
        break;
    }

    // Time part
    switch (timeFormat) {
      case 'ma':
        format += value.hour() === 0 ? '' : ' HH:mm';
        break;
      case 'm':
        format += ' HH:mm';
        break;
      case 's':
        format += ' HH:mm:ss';
        break;
    }

    return value.format(format);
  }

}
