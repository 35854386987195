// noinspection HttpUrlsUsage

export class OrgClaimTypes {

  public static readonly username: string = 'http://mchnad.com/username';
  public static readonly fullName: string = 'http://mchnad.com/fullName';
  public static readonly givenName: string = 'http://mchnad.com/givenName';
  public static readonly familyName: string = 'http://mchnad.com/familyName';
  public static readonly nickname: string = 'http://mchnad.com/nickname';
  public static readonly email: string = 'http://mchnad.com/email';
  public static readonly emailVerified: string = 'http://mchnad.com/emailVerified';
  public static readonly internalUser: string = 'http://mchnad.com/internalUser';
  public static readonly audienceType: string = 'http://mchnad.com/activeOrgType';
  public static readonly anonymisedDataOnly: string = 'http://mchnad.com/anon';

  public static all = [
    OrgClaimTypes.username,
    OrgClaimTypes.fullName,
    OrgClaimTypes.givenName,
    OrgClaimTypes.familyName,
    OrgClaimTypes.nickname,
    OrgClaimTypes.email,
    OrgClaimTypes.emailVerified,
    OrgClaimTypes.internalUser,
    OrgClaimTypes.audienceType,
    OrgClaimTypes.anonymisedDataOnly
  ];

}
