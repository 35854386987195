import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { ErrorComponent } from './error/error.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: 'error', component: ErrorComponent, data: { redirectOnRefresh: true, bypassUnsavedChanges: true } },
  { path: 'access-denied', component: AccessDeniedComponent, data: { redirectOnRefresh: true, bypassUnsavedChanges: true } },
  { path: '**', component: NotFoundComponent, data: { redirectOnRefresh: true, bypassUnsavedChanges: true } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorRoutingModule {}
