import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent {

  public constructor(
    public title: Title
  ) {
    this.title.setTitle('Basic Account');
  }

  public get supportEmail() { return environment.supportEmail; }

  public get companyName() { return environment.companyName; }

  public get productName() { return environment.productName; }

}
