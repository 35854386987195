export enum MobileOperatingSystem {
  iOS,
  Android,
  Web
}

export const MobileOperatingSystemDesc = [
  'iOS',
  'Android',
  'Web'
];
