// noinspection SpellCheckingInspection

import { extend } from 'lodash-es';
import { Permissions } from 'src/app/shared/auth/permissions';

export const environment = (() => {
  let env = {
    name: 'base',
    companyName: 'Kochava',
    productName: 'Portal',
    showDebugInfo: true,
    showEnvironmentInfo: true,
    angularProductionMode: false,
    auth0Domain: 'auth.machineadvertising.com',
    auth0HostedDomain: 'machineadvertising.eu.auth0.com',
    auth0ClientID: '5kZvQj91MP9ouHETGwAOMZDHfw3zXuV8',
    auth0Audience: 'https://api.machineadvertising.com',
    // tslint:disable-next-line: max-line-length
    auth0Scope: `openid profile email ${Permissions.all.join(' ')}`,
    auth0DbConnectionId: 'con_lxrehhl02RO1QbtQ',
    auth0CallbackURL: '',
    // Used to determine whether or not to add the authorization header to outgoing HTTP requests
    authDomainPattern: /^https?:\/\/(?:[a-z\d-]+\.)*(?:mchnad\.com|machineadvertising\.com|auth0\.com)/i,
    auth0SecurityAdminRole: 'rol_JrYbsp0K8mDP8Yo5',
    logLevel: 'warning', // debug | info | warning | error
    supportEmail: 'support@machineadvertising.com',
    salesPhone: '+44 (0)20 3286 7404',
    salesPhonePlain: '442032867404',
    version: '1860',
    clickServerDomain: 'api.mchnad.com',
    channelIconUrlBase: 'https://cdn.machineadvertising.com/channel-icons/',
    apiBase: '',
    rateLimitingClientId: `portal-ui-${((window as any)._envConfig)?.name ?? 'base'}`
  };

  // Extend the base config with deployment-specific parameters
  if ((window as any)._envConfig) { env = extend(env, (window as any)._envConfig); }

  return env;
})();
