export enum NetworkType {
  MobileDsp,
  DesktopDsp,
  AdTracker,
  CPINetwork,
  SelfAttributing,
  AffiliateNetwork
}

export const NetworkTypeDesc = [
  'Mobile DSP',
  'Desktop DSP',
  'Ad Tracker',
  'CPI Network',
  'Self Attributing',
  'Affiliate Network'
];
