import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HighchartsChartModule } from 'highcharts-angular';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NotFoundComponent } from '../shared/error/not-found/not-found.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmailUnverifiedComponent } from './email-unverified/email-unverified.component';
import { NoActiveOrganisationComponent } from './no-active-organisation/no-active-organisation.component';
import { UpgradeComponent } from './upgrade/upgrade.component';

@NgModule({
  declarations: [
    DashboardComponent,
    NotFoundComponent,
    NoActiveOrganisationComponent,
    EmailUnverifiedComponent,
    UpgradeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ProgressbarModule,
    TooltipModule,
    HighchartsChartModule
  ]
})
export class CoreModule {}
