import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-email-unverified',
  templateUrl: './email-unverified.component.html',
  styleUrls: ['./email-unverified.component.scss']
})
export class EmailUnverifiedComponent {

  public constructor(
    public title: Title,
    private readonly auth: AuthService,
    private readonly toastr: ToastrService
  ) {
    this.title.setTitle('Complete Signup');
  }

  public get supportEmail() { return environment.supportEmail; }

  public async resendVerificationEmail() {
    await this.auth.resendVerificationEmail();
    this.toastr.success(`An email with a link to verify your account has been sent to <strong>${this.auth.profile.email}</strong>`,
      'Verification email sent', {
        positionClass: 'toast-top-center',
        enableHtml: true
      });
  }

  public hardReload() { document.location.href = document.location.origin; }

}
