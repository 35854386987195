import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';

@Pipe({
  name: 'sentenceJoin'
})
export class SentenceJoinPipe implements PipeTransform {

  public transform(values: any[], useOr = false, quoted = false): string {
    if (!values.length) { return ''; }
    if (values.length === 1) { return values[0].toString(); }
    const parsedValues = values.filter(x => !isNil(x) && x !== '').map(x => quoted ? `"${x}"` : `${x}`);
    return `${parsedValues.slice(0, parsedValues.length - 1).join(', ')} ${useOr ? 'or' : 'and'} ${parsedValues[parsedValues.length - 1]}`;
  }

}
