import { Component, Input, ViewChild } from '@angular/core';
import { HelpBladeComponent } from '../../help/help-blade.component';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss']
})
export class ContentHeaderComponent {

  @ViewChild(HelpBladeComponent, { static: false }) public helpBlade: HelpBladeComponent;

  @Input() public title: string | string[];

  @Input() public class: string;

  @Input() public helpArticleId: string;

  @Input() public iconUrls?: string[];

  public get formattedTitle() {
    return Array.isArray(this.title) ? this.title.filter(x => x).join('<i class="fa-solid fa-angle-right"></i>') : this.title;
  }

  public async showHelp() { await this.helpBlade.open(this.helpArticleId); }

}
