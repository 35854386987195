import { Injectable, Injector, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ISavedToast } from '../saved-toast';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ToastOnLoadService {

  private readonly _storageKey = 'toastOnLoad';

  private _initialized = false;

  public constructor(
    private readonly sessionStorage: SessionStorageService,
    private readonly injector: Injector,
    private readonly zone: NgZone
  ) { }

  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  public async enqueue(toast: ISavedToast) {
    const toasts = this.sessionStorage.get<ISavedToast[]>(this._storageKey) || [];
    toasts.push(toast);
    this.sessionStorage.add(this._storageKey, toasts);
  }

  public initialize() {

    if (this._initialized) { return; }
    this._initialized = true;

    const toasts = this.sessionStorage.get<ISavedToast[]>(this._storageKey) || [];
    for (const toast of toasts) {
      this.toastr.show(toast.message, toast.title, toast.override, toast.type);
    }

    // Delay by a few seconds to prevent running before redirect scenarios (e.g. auth)
    setTimeout(() => this.zone.run(_ => {
      this.sessionStorage.remove(this._storageKey);
    }), 3000);
  }

}
