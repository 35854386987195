import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public get companyName() { return environment.companyName; }

  public get showEnvironment() { return environment.showEnvironmentInfo; }

  public get environmentName() { return environment.name; }

}
