import { Injectable } from '@angular/core';
import { IAdvertiserUserOrganisationDto } from 'src/app/api-model/advertiser-user-organisation-dto';
import { INetworkUserOrganisationDto } from 'src/app/api-model/network-user-organisation-dto';
import { AudienceType } from './audience-type';
import { AuthService } from './auth.service';
import { Permissions } from './permissions';

@Injectable({
  providedIn: 'root'
})
export class AudienceService {

  public constructor(private readonly auth: AuthService) { }

  public get showAnalysisByAdvertiser() { return this.type !== AudienceType.Advertiser; }

  public get showAnalysisByNetwork() { return this.type !== AudienceType.Network; }

  public get showAnalysisByPrimaryPublisher() {
    return this.type === AudienceType.Advertiser
      || (this.type === AudienceType.Network && this.network?.showPrimaryPublishers);
  }

  public get primaryPublisherLabel() {
    return this.type === AudienceType.Network && this.auth.profile.activeOrganisation
      ? this.network.primaryPublisherTerminology
      : 'Exchange';
  }

  public get canAccessReports() { return this.auth.profile?.activeOrganisationId && [AudienceType.Network, AudienceType.Advertiser].includes(this.type); }

  public get canAccessAggregatedReports() { return [AudienceType.Advertiser].includes(this.type); }

  public get canManageApps() { return this.auth.profile?.activeOrganisationId && [AudienceType.Network, AudienceType.Advertiser].includes(this.type) && this.hasPaidOrTrialAccount; }

  public get canManageCampaigns() { return this.auth.profile?.activeOrganisationId && [AudienceType.Network, AudienceType.Advertiser].includes(this.type); }

  public get canManagePublishers() {
    return this.auth.profile?.activeOrganisationId && [AudienceType.Network].includes(this.type)
      && this.auth.userHasScope(Permissions.configuration) && this.hasPaidOrTrialAccount;
  }

  public get canManageMacros() { return this.type === AudienceType.Network && this.auth?.userHasScope(Permissions.configuration); }

  public get canManageNetworks() { return this.type === AudienceType.Advertiser && this.auth?.userHasScope(Permissions.configuration); }

  public get canManageChannels() { return this.type === AudienceType.Advertiser && this.auth?.userHasScope(Permissions.configuration); }

  public get canManageRegions() { return this.type === AudienceType.Advertiser && this.auth?.userHasScope(Permissions.configuration); }

  public get canManageTestCodes() { return this.type === AudienceType.Advertiser && this.auth?.userHasScope(Permissions.configuration); }

  public get canManageUsers() { return this.auth?.userHasScope(Permissions.users); }

  public get canAccessDisputedInstalls() { return [AudienceType.Network, AudienceType.Advertiser].includes(this.type); }

  public get canAccessInstallDelivery() { return this.hasPaidOrTrialAccount || this.auth.userHasScope(Permissions.admin); }

  public get canAccessDetailedClicksAnalysis() { return this.hasPaidOrTrialAccount || this.auth.userHasScope(Permissions.admin); }

  public get canAccessFullUaIndex() { return this.hasPaidOrTrialAccount; }

  public get canAccessUniversalLinks() {
    return this.hasPaidOrTrialAccount && this.auth.userHasScope(Permissions.configuration) && (this.type === AudienceType.Network && this.network?.agencyId)
      || (this.type === AudienceType.Advertiser && this.advertiser?.canUseUniversalLinks);
  }

  public get canAccessBilling() { return this.auth.userHasScope(Permissions.configuration) && this.hasPaidOrTrialAccount; }

  public get canAccessDeveloperApi() { return this.auth.userHasScope(Permissions.developer) && this.hasPaidOrTrialAccount; }

  public get type() { return this.auth.profile?.audienceType; }

  public get name() { return AudienceType[this.type] || 'Organisation'; }

  public get slug() { return this.name?.toLowerCase(); }

  public get advertiser() { return this.type === AudienceType.Advertiser ? (this.auth.profile.activeOrganisation as IAdvertiserUserOrganisationDto) : null; }

  public get network() { return this.type === AudienceType.Network ? (this.auth.profile.activeOrganisation as INetworkUserOrganisationDto) : null; }

  public get hasPaidOrTrialAccount() {
    switch (this.type) {
      case AudienceType.Advertiser:
        return (this.advertiser || {}).paymentTier > 0;
      case AudienceType.Network:
        return (this.network || {}).paymentTier > 0;
      default:
        return false;
    }
  }

}
