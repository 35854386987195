import { Component, Input } from '@angular/core';
import { ClipboardService } from '../services/clipboard.service';

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss']
})
export class CopyToClipboardComponent {

  private readonly hideCopiedMessageDelay = 3000;

  private hideCopiedMessageHandle: number;

  public showCopiedMessage = false;

  @Input() public copyValue: any;

  @Input() public size: 'default' | 'compact' | 'mini' | 'micro' | '' = 'default';

  public constructor(private readonly clipboardService: ClipboardService) { }

  public copyToClipboard(value: any, event: MouseEvent) {

    event.stopPropagation();

    this.clipboardService.copyToClipboard(value);
    this.showCopiedMessage = true;

    if (this.hideCopiedMessageHandle) {
      window.clearTimeout(this.hideCopiedMessageHandle);
    }

    this.hideCopiedMessageHandle = window.setTimeout(() => {
      delete this.hideCopiedMessageHandle;
      this.showCopiedMessage = false;
    }, this.hideCopiedMessageDelay);
  }

}
