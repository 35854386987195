export enum ExchangeSubscriptionTier {
  Free,
  Trial,
  Tier2,
  Tier3,
  Tier4,
  Tier5,
  Tier6,
  Tier7
}

export const ExchangeSubscriptionTierDesc = [
  'Free',
  'Trial',
  'Tier 2',
  'Tier 3',
  'Tier 4',
  'Tier 5',
  'Tier 6',
  'Tier 7'
];
