import { CommonModule, CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LocalStorageModule } from 'angular-2-local-storage';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AuthorizationInterceptor } from './auth/authorization.interceptor';
import { CallbackComponent } from './auth/callback.component';
import { HasPaidOrTrialAccountDirective } from './auth/has-paid-or-trial-account.directive';
import { HasScopeDirective } from './auth/has-scope.directive';
import { HasValidAccountDirective } from './auth/has-valid-account.directive';
import { IdentityComponent } from './auth/identity.component';
import { LoginComponent } from './auth/login.component';
import { LogoutComponent } from './auth/logout.component';
import { ProfileComponent } from './auth/profile.component';
import { BusyIndicatorComponent } from './busy-indicator/busy-indicator.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { DateRangeTextBoxComponent } from './date-range/date-range-text-box.component';
import { AccessDeniedComponent } from './error/access-denied/access-denied.component';
import { ErrorHandlerService } from './error/error-handler.service';
import { ErrorInterceptor } from './error/error.interceptor';
import { ErrorComponent } from './error/error/error.component';
import { FirstCellComponent } from './first-cell/first-cell.component';
import { HelpBladeComponent } from './help/help-blade.component';
import { DateInterceptor } from './interceptors/date.interceptor';
import { BladeTabsComponent } from './layout/blade-tabs/blade-tabs.component';
import { BladeComponent } from './layout/blade/blade.component';
import { ContentHeaderComponent } from './layout/content-header/content-header.component';
import { DataFormComponent } from './layout/data-form/data-form.component';
import { DataTableComponent } from './layout/data-table/data-table.component';
import { HeaderComponent } from './layout/header/header.component';
import { HistoryBladeComponent } from './layout/history-blade/history-blade.component';
import { LeftnavComponent } from './layout/leftnav/leftnav.component';
import { OutletComponent } from './layout/outlet/outlet.component';
import { PageHeaderComponent } from './layout/page-header/page-header.component';
import { PageSecondaryTabsComponent } from './layout/page-secondary-tabs/page-secondary-tabs.component';
import { PageTabsComponent } from './layout/page-tabs/page-tabs.component';
import { PageThirdLevelTabsComponent } from './layout/page-third-level-tabs/page-third-level-tabs.component';
import { PageTopFiltersComponent } from './layout/page-top-filters/page-top-filters.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { AccreditationLevelNamePipe } from './pipes/accreditation-level-name.pipe';
import { AdvertiserSubscriptionTierNamePipe } from './pipes/advertiser-subscription-tier-name.pipe';
import { AppIconUrlLargePipe } from './pipes/app-icon-url-large.pipe';
import { AppIconUrlSmallPipe } from './pipes/app-icon-url-small.pipe';
import { BoolIconPipe } from './pipes/bool-icon.pipe';
import { BrJoinPipe } from './pipes/br-join.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { DecodeWhitespacePipe } from './pipes/decode-whitespace.pipe';
import { ExchangeSubscriptionTierNamePipe } from './pipes/exchange-subscription-tier-name.pipe';
import { FormatSecondsPipe } from './pipes/format-seconds.pipe';
import { FraudMetricsLabelPipe } from './pipes/fraud-metrics-label.pipe';
import { IsoWeekPipe } from './pipes/iso-week.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { MchnDatePipe } from './pipes/mchn-date.pipe';
import { MonthNamePipe } from './pipes/month-name.pipe';
import { NetworkSubscriptionTierNamePipe } from './pipes/network-subscription-tier-name.pipe';
import { NetworkTypeNamePipe } from './pipes/network-type-name.pipe';
import { NumberWordPipe } from './pipes/number-word.pipe';
import { OsFontIconPipe } from './pipes/os-font-icon.pipe';
import { OsIconPipe } from './pipes/os-icon.pipe';
import { OsNamePipe } from './pipes/os-name.pipe';
import { PercentOfPipe } from './pipes/percent-of.pipe';
import { PercentageChangeFromPipe } from './pipes/percentage-change-from.pipe';
import { QuarterConstrainedWeekPipe } from './pipes/quarter-constrained-week.pipe';
import { SentenceJoinPipe } from './pipes/sentence-join.pipe';
import { ShortCurrencyPipe } from './pipes/short-currency.pipe';
import { ShortDateRangePipe } from './pipes/short-date-range.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { ShortenPipe } from './pipes/shorten.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TimespanPipe } from './pipes/timespan.pipe';
import { TrustScoreColourClassPipe } from './pipes/trust-score-colour-class.pipe';
import { UrlDecodePipe } from './pipes/url-decode.pipe';
import { ShortenLongTextComponent } from './shorten-long-text/shorten-long-text.component';
import { FormValidationMessagesComponent } from './validation/form-validation-messages.component';
import { MonthAndYearPipe } from './pipes/month-and-year.pipe';
import { ToggleRevealDirective } from './directives/toggle-reveal.directive';
import { WorkInProgressComponent } from './work-in-progress/work-in-progress.component';
import { FileDownloadProgressComponent } from './file-download-progress/file-download-progress.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { FocusDirective } from './directives/focus.directive';
import { RateLimitingClientInterceptor } from './interceptors/rate-limiting-client.interceptor';
import { RatioPipe } from './pipes/ratio.pipe';
import { ButtonDateRangeComponent } from './date-range/button-date-range.component';
import { ButtonTabsComponent } from './layout/button-tabs/button-tabs.component';
import { NeutralPercentageChangeFromPipe } from './pipes/neutral-percentage-change-from.pipe';
import { RoundPipe } from './pipes/round.pipe';
import { BytesPipe } from './pipes/bytes.pipe';
import { LastPipe } from './pipes/last.pipe';
import { FirstPipe } from './pipes/first.pipe';
import { PercentageDifferenceFromPipe } from './pipes/percentage-difference-from.pipe';
import { NeutralPercentageDifferenceFromPipe } from './pipes/neutral-percentage-difference-from.pipe';
import { WeekDateRangePipe } from './pipes/week-date-range.pipe';
import { ShortCurrencyWithTooltipComponent } from './short-currency-with-tooltip/short-currency-with-tooltip.component';
import { ShortNumberWithTooltipComponent } from './short-number-with-tooltip/short-number-with-tooltip.component';
import { DollarsWithCentsTooltipComponent } from './dollars-with-cents-tooltip/dollars-with-cents-tooltip.component';
import { SignupComponent } from './auth/signup.component';
import { RatioWithTooltipComponent } from './ratio-with-tooltip/ratio-with-tooltip.component';

@NgModule({
  declarations: [
    AccessDeniedComponent,
    AccreditationLevelNamePipe,
    AdvertiserSubscriptionTierNamePipe,
    AppIconUrlSmallPipe,
    AppIconUrlLargePipe,
    BladeComponent,
    BladeTabsComponent,
    BoolIconPipe,
    BrJoinPipe,
    BusyIndicatorComponent,
    BytesPipe,
    CallbackComponent,
    ContentHeaderComponent,
    ContentHeaderComponent,
    CopyToClipboardComponent,
    CustomDatePipe,
    DataFormComponent,
    DataTableComponent,
    DateRangeTextBoxComponent,
    DecodeWhitespacePipe,
    ErrorComponent,
    ExchangeSubscriptionTierNamePipe,
    FirstCellComponent,
    FirstPipe,
    FormatSecondsPipe,
    FormValidationMessagesComponent,
    FraudMetricsLabelPipe,
    ButtonDateRangeComponent,
    HasPaidOrTrialAccountDirective,
    HasScopeDirective,
    HasValidAccountDirective,
    HeaderComponent,
    HelpBladeComponent,
    HistoryBladeComponent,
    IdentityComponent,
    IsoWeekPipe,
    JoinPipe,
    LastPipe,
    LeftnavComponent,
    LoadingIndicatorComponent,
    LoginComponent,
    LogoutComponent,
    MchnDatePipe,
    MessageBoxComponent,
    MonthAndYearPipe,
    MonthNamePipe,
    MultiSelectComponent,
    NetworkSubscriptionTierNamePipe,
    NetworkTypeNamePipe,
    NeutralPercentageChangeFromPipe,
    NeutralPercentageDifferenceFromPipe,
    NumberWordPipe,
    OsFontIconPipe,
    OsIconPipe,
    OsNamePipe,
    OutletComponent,
    PageHeaderComponent,
    PageSecondaryTabsComponent,
    PageTabsComponent,
    PageThirdLevelTabsComponent,
    PageTopFiltersComponent,
    PercentOfPipe,
    PercentageChangeFromPipe,
    PercentageDifferenceFromPipe,
    ProfileComponent,
    RatioPipe,
    RoundPipe,
    SentenceJoinPipe,
    ShortCurrencyPipe,
    ShortDateRangePipe,
    ShortenLongTextComponent,
    ShortenPipe,
    ShortNumberPipe,
    TimeAgoPipe,
    TimespanPipe,
    ToggleRevealDirective,
    TrustScoreColourClassPipe,
    QuarterConstrainedWeekPipe,
    UrlDecodePipe,
    WeekDateRangePipe,
    WorkInProgressComponent,
    FileDownloadProgressComponent,
    FocusDirective,
    ButtonTabsComponent,
    ShortCurrencyWithTooltipComponent,
    ShortNumberWithTooltipComponent,
    DollarsWithCentsTooltipComponent,
    SignupComponent,
    RatioWithTooltipComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LocalStorageModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    FormsModule,

    BsDropdownModule,
    BsDatepickerModule,
    PopoverModule,
    TooltipModule,
    NgSelectModule,
    UiSwitchModule,
    ProgressbarModule,
    ReactiveFormsModule
  ],
  exports: [
    AccessDeniedComponent,
    AccreditationLevelNamePipe,
    AdvertiserSubscriptionTierNamePipe,
    DateRangeTextBoxComponent,
    AppIconUrlSmallPipe,
    AppIconUrlLargePipe,
    BladeComponent,
    BladeTabsComponent,
    BoolIconPipe,
    BrJoinPipe,
    BusyIndicatorComponent,
    ButtonTabsComponent,
    BytesPipe,
    CallbackComponent,
    ContentHeaderComponent,
    ContentHeaderComponent,
    CopyToClipboardComponent,
    CustomDatePipe,
    DataFormComponent,
    DataTableComponent,
    DateRangeTextBoxComponent,
    DecodeWhitespacePipe,
    ErrorComponent,
    ExchangeSubscriptionTierNamePipe,
    FirstPipe,
    FirstCellComponent,
    FocusDirective,
    FormatSecondsPipe,
    FormValidationMessagesComponent,
    FraudMetricsLabelPipe,
    ButtonDateRangeComponent,
    HasPaidOrTrialAccountDirective,
    HasScopeDirective,
    HasValidAccountDirective,
    HeaderComponent,
    HelpBladeComponent,
    HistoryBladeComponent,
    IdentityComponent,
    IsoWeekPipe,
    JoinPipe,
    LastPipe,
    LeftnavComponent,
    LoadingIndicatorComponent,
    LoginComponent,
    LogoutComponent,
    MchnDatePipe,
    MonthAndYearPipe,
    MonthNamePipe,
    MultiSelectComponent,
    NetworkSubscriptionTierNamePipe,
    NetworkTypeNamePipe,
    NeutralPercentageChangeFromPipe,
    NeutralPercentageDifferenceFromPipe,
    NumberWordPipe,
    OsFontIconPipe,
    OsIconPipe,
    OsNamePipe,
    OutletComponent,
    PageHeaderComponent,
    PageSecondaryTabsComponent,
    PageTabsComponent,
    PageThirdLevelTabsComponent,
    PageTopFiltersComponent,
    PercentOfPipe,
    PercentageChangeFromPipe,
    PercentageDifferenceFromPipe,
    ProfileComponent,
    RatioPipe,
    RoundPipe,
    RouterModule,
    SentenceJoinPipe,
    ShortCurrencyPipe,
    ShortDateRangePipe,
    ShortenLongTextComponent,
    ShortenPipe,
    ShortNumberPipe,
    TimeAgoPipe,
    TimespanPipe,
    ToggleRevealDirective,
    TrustScoreColourClassPipe,
    QuarterConstrainedWeekPipe,
    UrlDecodePipe,
    WorkInProgressComponent,
    WeekDateRangePipe,
    ShortCurrencyWithTooltipComponent,
    ShortNumberWithTooltipComponent,
    DollarsWithCentsTooltipComponent,
    RatioWithTooltipComponent
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AccreditationLevelNamePipe,
        AdvertiserSubscriptionTierNamePipe,
        AppIconUrlLargePipe,
        AppIconUrlSmallPipe,
        BrJoinPipe,
        BytesPipe,
        CurrencyPipe,
        DecimalPipe,
        ExchangeSubscriptionTierNamePipe,
        IsoWeekPipe,
        JoinPipe,
        MchnDatePipe,
        NetworkSubscriptionTierNamePipe,
        NetworkTypeNamePipe,
        NeutralPercentageChangeFromPipe,
        NumberWordPipe,
        OsFontIconPipe,
        OsIconPipe,
        OsNamePipe,
        PercentageChangeFromPipe,
        PercentOfPipe,
        PercentPipe,
        QuarterConstrainedWeekPipe,
        RatioPipe,
        RoundPipe,
        SentenceJoinPipe,
        ShortCurrencyPipe,
        ShortDateRangePipe,
        ShortNumberPipe,
        TimeAgoPipe,
        ToggleRevealDirective,
        TrustScoreColourClassPipe,
        WeekDateRangePipe,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true }, // Converts date strings to dates
        { provide: HTTP_INTERCEPTORS, useClass: RateLimitingClientInterceptor, multi: true }, // Adds X-ClientId header for rate limiter
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true }, // Handles 401
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true } // Handles 500
      ]
    };
  }
}
