import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ExchangeSubscriptionTier } from 'src/app/api-model/enums/exchange-subscription-tier';

@Injectable()
@Pipe({ name: 'exchangeSubscriptionTierName' })
export class ExchangeSubscriptionTierNamePipe implements PipeTransform {

  public transform(value: ExchangeSubscriptionTier, useBadge = true): string {
    switch (value) {
      case ExchangeSubscriptionTier.Free:
        return 'Free';
      case ExchangeSubscriptionTier.Trial:
        return useBadge ? '<div class="icon-text-tag grey"><i class="fa-solid fa-balance-scale"></i> Trial</div>' : 'Trial';
      case ExchangeSubscriptionTier.Tier2:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 2</div>' : 'Tier 2';
      case ExchangeSubscriptionTier.Tier3:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 3</div>' : 'Tier 3';
      case ExchangeSubscriptionTier.Tier4:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 4</div>' : 'Tier 4';
      case ExchangeSubscriptionTier.Tier5:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 5</div>' : 'Tier 5';
      case ExchangeSubscriptionTier.Tier6:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 6</div>' : 'Tier 6';
      case ExchangeSubscriptionTier.Tier7:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 7</div>' : 'Tier 7';
      default:
        return '';
    }
  }

}
