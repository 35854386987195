import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trustScoreColourClass'
})
export class TrustScoreColourClassPipe implements PipeTransform {

  public transform(value: number): string {
    if (value < 50) { return 'bad score'; }
    if (value < 70) { return 'medium score'; }
    return 'good score';
  }

}
