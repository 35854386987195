import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

/**
 * Disables this interceptor for a given HTTP request
 * Usage: this.http.get(`${environment.apiBase}someEndpoint`, { headers: new HttpHeaders().set(SkipErrorHandlerHeader, '') })
 */
export const SkipErrorHandlerHeader = 'X-Skip-Error-Handler';
export const SkipModelStateError = 'X-Skip-Error-Handler-ModelState';
export const SkipNotAcceptableError = 'X-Skip-Error-Handler-NotAcceptable';
export const SkipConflictError = 'X-Skip-Error-Handler-Conflict';
export const SkipPayloadTooLargeError = 'X-Skip-Payload-Too-Large';

export interface IRetryParams {
  maxRetries?: number;
  scalingDuration?: number;
  shouldRetry?: (config: { status: number }) => boolean;
}

const defaultRetryParams: IRetryParams = {
  maxRetries: 2,
  scalingDuration: 500,
  shouldRetry: ({ status }) => status >= 0
};

export const retryStrategy = (params: IRetryParams = {}) => (attempts: Observable<any>) => attempts.pipe(
  mergeMap((error, i) => {
    const { maxRetries, scalingDuration, shouldRetry } = { ...defaultRetryParams, ...params };
    const retryAttempt = i + 1;
    // If maximum number of retries have been met or response is a status code we don't wish to retry, throw error
    if (retryAttempt > maxRetries || !shouldRetry(error)) {
      return throwError(error);
    }
    // Retry after 1s, 2s, etc...
    return timer(retryAttempt * scalingDuration);
  })
);

/**
 * Handles HTTP error responses by forwarding the user to the relevant page depending on the error type
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  public constructor(
    private readonly errorHandler: ErrorHandlerService,
    private readonly router: Router
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Abort if the request has instructed us via a custom header to do so
    if (req.headers.has(SkipErrorHandlerHeader)) {
      // Delete the temporary header otherwise ASP.NET might get spooked by the unrecognised header
      const headers = req.headers.delete(SkipErrorHandlerHeader);
      return next.handle(req.clone({ headers }));
    }

    // Capture and remove headers that instruct us to ignore certain response codes

    const skipModelStateError = req.headers.has(SkipModelStateError);
    if (skipModelStateError) { req.headers.delete(SkipModelStateError); }

    const skipNotAcceptable = req.headers.has(SkipNotAcceptableError);
    if (skipNotAcceptable) { req.headers.delete(SkipNotAcceptableError); }

    const skipConflict = req.headers.has(SkipConflictError);
    if (skipConflict) { req.headers.delete(SkipConflictError); }

    const skipPayloadTooLarge = req.headers.has(SkipPayloadTooLargeError);
    if (skipPayloadTooLarge) { req.headers.delete(SkipPayloadTooLargeError); }

    return next.handle(req).pipe(
      // Adopt a strategy to retry failed HTTP requests
      retryWhen(retryStrategy({
        // The only error that we want to retry for is status code zero. We know there's some kind of weird intermittent glitch that
        // sometimes causes this to happen, and that's what we're protecting against here
        shouldRetry: ({ status }) => status === 0,
        maxRetries: 2,
        // Separate retries by 1 second
        scalingDuration: 500
      })),

      catchError((error: HttpErrorResponse) => {

        // Would be weird if we ended up here, but just in case
        if (!error) { return next.handle(req); }

        let locationChangeOnError = false;
        try {
          locationChangeOnError = !!this.router.routerState.root.firstChild.snapshot.data.locationChangeOnError;
        } catch { }

        switch (error.status) {

          // Unreachable
          case 0:
            this.errorHandler.handleError(new Error(`The requested URL (${error.url}) is unreachable`), true, false);
            break;

          // Bad request or Unprocessable entity
          case 400:
          case 422:
            if (!skipModelStateError && error.error && error.error.errors) {
              this.errorHandler.handleError(error);
            }
            break;

          // Forbidden
          case 403:
            this.router.navigate(['/access-denied'], { skipLocationChange: !locationChangeOnError });
            break;

          // Not Acceptable
          case 406:
            if (!skipNotAcceptable) {
              this.errorHandler.handleError(error);
            }
            break;

          // Conflict
          case 409:
            if (!skipConflict) {
              this.errorHandler.handleError(error);
            }
            break;

          // Payload Too Large
          case 413:
            if (!skipPayloadTooLarge) {
              this.errorHandler.handleError(error);
            }
            break;

          // Internal server error
          case 500:
            // Pass the error to our error handler. Instruct it to show, but not log the error
            // because this error came from the server where it would have already been logged
            this.errorHandler.handleError(error, true, false);
            break;
        }

        // Throw on the observable, in case the subscribers want to do any further processing
        throw error;
      })
    );

  }

}
