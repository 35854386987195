import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Data, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AudienceService } from './audience.service';

/**
 * Guards access to a route by testing that the authenticated user has the correct audience type.
 * Requires the route to have a data property defined as follows (obviously substituting the required audience type):
 *     data: { requiredAudience: AudienceType.Network }
 *
 * If the guard test fails and the user doesn't have the required audience, the user will be redirected to AccessDeniedComponent.
 * It will also implicitly force the user to log in if they haven't done so already
 */
@Injectable({
  providedIn: 'root'
})
export class AudienceTypeGuard implements CanActivate, CanActivateChild, CanLoad {

  public constructor(private readonly audience: AudienceService, public router: Router) { }

  // noinspection JSUnusedLocalSymbols
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guard(next.data);
  }

  // noinspection JSUnusedLocalSymbols
  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guard(next.data);
  }

  // noinspection JSUnusedLocalSymbols
  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.guard(route.data);
  }

  private async guard(data: Data) {
    return (data as any).requiredAudiences
      ? (data as any).requiredAudiences.includes(this.audience.type)
      : this.audience.type === (data as any).requiredAudience;
  }
}
