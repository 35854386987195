export enum AccreditationLevel {
  Unaccredited,
  Bronze,
  Silver,
  Gold
}

export const AccreditationLevelDesc = [
  'Unaccredited',
  'Bronze',
  'Silver',
  'Gold'
];
