import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ratio'
})
export class RatioPipe implements PipeTransform {

  public constructor(private readonly decimalPipe: DecimalPipe) { }

  public transform(value: string | number, digitsInfo: string = '1.2-2'): string {
    if (typeof value === 'string') { value = parseFloat(value); }
    if (!value) { value = 0; }
    return `${this.decimalPipe.transform(value, digitsInfo)} : 1`;
  }

}
