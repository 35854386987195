<header [class]="class">
  <div class="inner">
    <ul *ngIf="iconUrls?.length" class="icons">
      <li *ngFor="let iconUrl of iconUrls"><img [src]="iconUrl" alt="" referrerpolicy="no-referrer" /></li>
    </ul>
    <h3 [innerHTML]="formattedTitle"></h3>
    <!-- <button (click)="showHelp()" *ngIf="helpArticleId" class="btn btn-primary help"><i class="fa-solid fa-circle-question"></i> Help</button> -->
    <ng-content select=".left-content"></ng-content>
    <div class="spacer"></div>
    <ng-content select=".right-content"></ng-content>
  </div>
</header>

<!--suppress JSUnusedGlobalSymbols -->
<app-help-blade #helpBlade></app-help-blade>
