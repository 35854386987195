import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { DashboardResolver } from './core/dashboard/dashboard.resolver';
import { EmailUnverifiedComponent } from './core/email-unverified/email-unverified.component';
import { NoActiveOrganisationComponent } from './core/no-active-organisation/no-active-organisation.component';
import { UpgradeComponent } from './core/upgrade/upgrade.component';
import { AudienceType } from './shared/auth/audience-type';
import { AudienceTypeGuard } from './shared/auth/audience-type.guard';
import { Permissions } from './shared/auth/permissions';
import { ScopeGuard } from './shared/auth/scope.guard';
import { ValidAccountGuard } from './shared/auth/valid-account.guard';
import { OutletComponent } from './shared/layout/outlet/outlet.component';

const routes: Routes = [
  {
    path: '',
    component: OutletComponent,
    children: [
      // Lazy-loaded feature modules
      {
        path: 'analysis',
        loadChildren: () => import('./analysis/analysis.module').then(m => m.AnalysisModule),
        canLoad: [ValidAccountGuard]
      },
      {
        path: 'configuration',
        loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
        canLoad: [ValidAccountGuard, ScopeGuard, AudienceTypeGuard],
        data: {
          requiredScopes: [Permissions.configuration],
          requiredAudiences: [AudienceType.Advertiser, AudienceType.Network]
        }
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canLoad: [ValidAccountGuard, ScopeGuard],
        data: { requiredScopes: [Permissions.admin] }
      },
      // {
      //   path: 'ua-index',
      //   loadChildren: () => import('./ua-index/ua-index.module').then(m => m.UaIndexModule),
      //   canLoad: [ValidAccountGuard]
      // },

      // Core routes
      { path: 'email-unverified', component: EmailUnverifiedComponent, data: { redirectOnRefresh: true } },
      { path: 'no-active-organisation', component: NoActiveOrganisationComponent, data: { redirectOnRefresh: true } },
      { path: 'basic-tier-account', component: UpgradeComponent, data: { redirectOnRefresh: true } },

      // The default (home) route
      // {
      //   path: '', component: DashboardComponent,
      //   pathMatch: 'full',
      //   canActivate: [ValidAccountGuard],
      //   resolve: { dashboard: DashboardResolver }
      // }
      {
        path: '',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canLoad: [ValidAccountGuard, ScopeGuard],
        data: { requiredScopes: [Permissions.admin] }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    urlUpdateStrategy: 'eager',
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
