import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AdvertiserSubscriptionTier } from 'src/app/api-model/enums/advertiser-subscription-tier';

@Injectable()
@Pipe({ name: 'advertiserSubscriptionTierName' })
export class AdvertiserSubscriptionTierNamePipe implements PipeTransform {

  public transform(value: AdvertiserSubscriptionTier, useBadge = true): string {
    switch (value) {
      case AdvertiserSubscriptionTier.Free:
        return 'Free';
      case AdvertiserSubscriptionTier.Trial:
        return useBadge ? '<div class="icon-text-tag grey"><i class="fa-solid fa-balance-scale"></i> Trial</div>' : 'Trial';
      case AdvertiserSubscriptionTier.Tier2:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 2</div>' : 'Tier 2';
      case AdvertiserSubscriptionTier.Tier3:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 3</div>' : 'Tier 3';
      case AdvertiserSubscriptionTier.Tier4:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 4</div>' : 'Tier 4';
      case AdvertiserSubscriptionTier.Tier5:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 5</div>' : 'Tier 5';
      case AdvertiserSubscriptionTier.Tier6:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 6</div>' : 'Tier 6';
      case AdvertiserSubscriptionTier.Tier7:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Tier 7</div>' : 'Tier 7';
      default:
        return '';
    }
  }

}
