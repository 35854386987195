import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { NetworkType } from 'src/app/api-model/enums/network-type';

@Injectable()
@Pipe({ name: 'networkTypeName' })
export class NetworkTypeNamePipe implements PipeTransform {

  public transform(value: NetworkType): string {
    switch (value) {
      case NetworkType.AdTracker:
        return 'Ad Tracker';
      case NetworkType.AffiliateNetwork:
        return 'Affiliate Network';
      case NetworkType.CPINetwork:
        return 'CPI Network';
      case NetworkType.DesktopDsp:
        return 'Desktop DSP';
      case NetworkType.MobileDsp:
        return 'Mobile DSP';
      case NetworkType.SelfAttributing:
        return 'Self Attributing';
      default:
        return '';
    }
  }

}
