// noinspection HttpUrlsUsage

export class NetworkClaimTypes {

  public static readonly networkIds: string = 'http://mchnad.com/nt/orgIds';
  public static readonly activeNetworkId: string = 'http://mchnad.com/nt/activeOrgId';

  public static all = [
    NetworkClaimTypes.networkIds,
    NetworkClaimTypes.activeNetworkId
  ];

}
