import { Component } from '@angular/core';
import { groupBy, orderBy, uniq } from 'lodash-es';
import { IUserOrganisationDto } from 'src/app/api-model/user-organisation-dto';
import { ThemeService } from '../services/theme.service';
import { AudienceType } from './audience-type';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss']
})
export class IdentityComponent {

  public constructor(
    private readonly auth: AuthService,
    private readonly theme: ThemeService
  ) { }

  public get profile() {
    return this.auth.profile;
  }

  public get authenticated() {
    return this.auth.authenticated;
  }

  public get name() {
    return this.auth.profile.name;
  }

  public get dark() { return this.theme.dark; }

  public set dark(value: boolean) { this.theme.dark = value; }

  public get switchToOrganisationsHasMultipleAudienceTypes() { return uniq(this.profile.otherOrganisations.map(x => x.audienceType)).length > 1; }

  public get switchToOrganisations() {
    return orderBy(Object.entries(groupBy(this.profile?.otherOrganisations?.filter(x => x.active) || [], x => `${AudienceType[x.audienceType]}s`)).map(([key, value]) => {
      return {
        audienceType: key,
        organisations: orderBy(value as IUserOrganisationDto[], [x => (x as any).paymentTier, x => x.displayName], ['desc', 'asc'])
      };
    }), x => ['Advertisers', 'Networks'].indexOf(x.audienceType));
  }

  public async switchActiveOrganisation(organisation: IUserOrganisationDto) {
    await this.auth.switchActiveOrganisation(organisation, organisation.audienceType);
  }

  public logout() {
    this.auth.logout();
  }

  public login() {
    this.auth.login();
  }

}
