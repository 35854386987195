import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';
import { isDate } from 'ngx-bootstrap/chronos';

@Pipe({
  name: 'weekDateRange'
})
export class WeekDateRangePipe implements PipeTransform {

  public transform(value: string | Date | Moment): string {
    if (value && (typeof value === 'string' || isDate(value))) { value = moment.utc(value); }
    if (moment.isMoment(value) && value.isValid() && value.year() >= 1900) {
      const end = value.clone().endOf('week').add(1, 'day');
      return value.month() === end.month()
        ? `${value.format('D')}-${end.format('D MMM')}`
        : `${value.format('D MMM')}-${end.format('D MMM')}`;
    }
    return '';
  }

}
